<template>
<div id="page-indicadores-compras">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Indicadores de Compras" class="elevation-1 px-5 py-3">
          <v-card-text>

            <v-row>
                <v-col>
                    <div>
                    <v-label><strong>Configuración:</strong> </v-label><br>
                    Considerar <input v-model="dias_ventas" @keyup="soloNumeros(dias_ventas)" style="text-align:center;background-color: white!important;border-style:inset !important;width:40px;"> días de ventas para el calculo de <u><span title="Minimo, Reorden, Máximo, Por Pedir">Stock</span></u>
                    <br><br>
                    Considerar <input v-model="dias_calculo" @keyup="soloNumeros(dias_calculo)" style="text-align:center;background-color: white!important;border-style:inset !important;width:40px;"> días a surtir el artículo para el calculo de Máximo y Por Pedir
                    <br><br>
                    Considerar <input v-model="dias_compras" @keyup="soloNumeros(dias_compras)" style="text-align:center;background-color: white!important;border-style:inset !important;width:40px;"> días para la búsqueda del artículo en Compras <span title="Se toma en cuenta la fecha de creación del pedido"><v-icon small>info</v-icon></span>
                    <br><br>
                    Considerar <input v-model="dias_entrega" @keyup="soloNumeros(dias_entrega)" style="text-align:center;background-color: white!important;border-style:inset !important;width:40px;"> días en promedio que tarda el proveedor en entregar el artículo, para el calculo de <u><span title="Minimo, Reorden, Máximo, Por Pedir">Stock</span></u>
                    <br><br>
                    Considerar existencia total menor a <input v-model="existencia_clave" @keyup="soloNumeros(existencia_clave)" style="text-align:center;background-color: white!important;border-style:inset !important;width:40px;"> para el indicador amarillo de Artículos Por Pedir
                    </div>
                </v-col>
                <v-col>
                    <div>
                    <v-label><strong>Indicadores:</strong> </v-label>&nbsp;
                        <v-btn x-small fab class="btnRefreshTable"
                            :loading="loading"                      
                            color="info" 
                            title="Actualizar Indicadores" 
                            @click="actualizarIndicadores()">
                            <v-icon>refresh</v-icon>            
                        </v-btn>
                    <br>
                        <v-btn color="red" small @click.native="excel_articulos_cero()" :loading="loading_articulos_cero">
                            <v-icon>mdi-file-excel</v-icon> {{formatNumberDecCero(total_articulos_cero)}} Artículos con existencia menor o igual a cero
                        </v-btn>  
                            <exportar-excel v-show="false" id="btn_existencia_cero" :data="articulos_existencia_cero" :exportFields="encabezadoArticulosCero" name="Artículos_Existencia_Cero.xlsx">
                                <v-btn fab dark x-small color="indigo darken-2">
                                    <v-icon dark>receipt</v-icon>
                                </v-btn>
                            </exportar-excel>                      
                        <br><br>
                        
                        <v-btn color="yellow" small @click.native="excel_articulos_pedir()" :loading="loading_articulos_cero">
                            <v-icon>mdi-file-excel</v-icon> {{formatNumberDecCero(total_articulos_menor)}} Artículos Por Pedir con existencia menor a {{existencia_clave}}
                        </v-btn>
                            <exportar-excel v-show="false" id="btn_articulos_pedir" :data="articulos_por_pedir" :exportFields="encabezadoArticulosCero" name="Artículos_Por_Pedir.xlsx">
                                <v-btn fab dark x-small color="indigo darken-2">
                                    <v-icon dark>receipt</v-icon>
                                </v-btn>
                            </exportar-excel>
                        <br><br>

                        <v-btn color="orange" small @click.native="excel_articulos_no_llegaron()" :loading="loading_articulos_no_llegaron"
                            :title="'Artículos en pedidos de los últimos ' +dias_compras+' días que NO se recibieron completos en alguna factura o No han llegado'">
                            <v-icon>mdi-file-excel</v-icon> {{formatNumberDecCero(total_articulos_no_llegaron)}} Artículos en pedidos que No llegaron o NO han llegado
                        </v-btn>
                            <exportar-excel v-show="false" id="btn_articulos_no_llegaron" :data="articulos_no_llegaron" :exportFields="encabezadoArticulosLlegaron" name="Artículos_No_Han_Llegado.xlsx">
                                <v-btn fab dark x-small color="indigo darken-2">
                                    <v-icon dark>receipt</v-icon>
                                </v-btn>
                            </exportar-excel>
                        <br><br>
                        
                        <v-btn color="green" small @click.native="excel_articulos_si_llegaron()" :loading="loading_articulos_no_llegaron"
                            :title="'Artículos en pedidos de los últimos ' +dias_compras+' días que SI se recibieron en alguna factura'">
                            <v-icon>mdi-file-excel</v-icon> {{formatNumberDecCero(total_articulos_si_llegaron)}} Artículos en pedidos que SI llegaron
                        </v-btn>
                            <exportar-excel v-show="false" id="btn_articulos_si_llegaron" :data="articulos_si_llegaron" :exportFields="encabezadoArticulosLlegaron" name="Artículos_Si_Llegaron.xlsx">
                                <v-btn fab dark x-small color="indigo darken-2">
                                    <v-icon dark>receipt</v-icon>
                                </v-btn>
                            </exportar-excel>
                    </div>
                </v-col>
            </v-row>

            <br><p>
            <div>
                <v-label><strong>Filtros de Búsqueda:</strong> </v-label><br>
                <div id="combos_busqueda">
                    <v-row id="row_combos">
                        <v-col sm="4" md="4" lg="4">
                            <v-autocomplete dense v-model="id_articulo" :items="articulos" :hide-no-data="true"
                            :loading="loadingArticulos" ref="selectarticulo" placeholder="Ingrese nombre de artículo"
                            prepend-icon="search" clearable item-text="nombre" item-value="_id"
                            @change="consultarArticulo()" label="Artículo">
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="4" md="4" lg="4">
                            <v-autocomplete dense v-model="id_proveedor" :items="proveedores_combo" :hide-no-data="true"
                            :loading="loadingProveedores"
                            ref="selectproveedor" placeholder="Ingrese nombre de proveedor" prepend-icon="search" clearable
                            item-text="nombre" item-value="_id" @change="consultarProveedor_combo()" label="Proveedor">
                            </v-autocomplete>
                            <span style="font-weight:bold;color:red;" v-show="(id_proveedor!=null && id_proveedor!=undefined && id_proveedor!='')">El Plazo de Entrega ha sido cambiado al plazo del proveedor: {{dias_entrega}} días</span>
                        </v-col>
                        <v-col sm="3" md="3" lg="3">
                            <v-autocomplete dense v-model="nombre_categoria"
                            :items="categorias" :hide-no-data="true" :loading="loadingCategorias"
                            ref="selectcategoria" placeholder="Ingrese nombre de categoría"
                            prepend-icon="search" clearable item-text="nombre" item-value="nombre"
                            @change="consultarCategoria()" label="Categoría">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </div>
            </div>

            
              <v-client-table ref="vuetable" :data="articulos_encontrados" :columns="columns" :options="options" class="elevation-2 pa-2" :key="lakey">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="codigo_barras" slot-scope="props">
                  {{props.row.codigo_barras.join(", ")}}
                </template>

                <template slot="categoria" slot-scope="props">
                  {{props.row.categoria[0]}}
                </template>

                <template slot="actual" slot-scope="props">
                    <div style="text-align:center !important;">
                        <v-chip x-small color="gray" text-color="black" title="Ver Existencias" v-tippy 
                            @click="ver_existencias(props.row)">
                            <b>{{formatNumberDecCero(props.row.actual,2)}}</b>
                        </v-chip>
                    </div>                    
                </template>

                <template slot="compras_30_dias" slot-scope="props">
                    <div style="text-align:center !important;">                        
                      <v-chip x-small color="gray" text-color="black" :title="'Ver Compras ('+dias_compras+' días)'" v-tippy 
                            @click="showCompras(props.row._id)">
                            <b>{{formatNumberDecCero(props.row.compras_30_dias,0)}}</b>
                        </v-chip>
                    </div>                    
                </template>

                <template slot="ventas_30_dias" slot-scope="props">
                    <div style="text-align:center !important;">                        
                      <v-chip x-small color="gray" text-color="black" :title="'Ver Ventas ('+dias_ventas+' días)'" v-tippy 
                            @click="getVentas(props.row._id, props.row.actual)">
                            <b>{{formatNumberDecCero(props.row.ventas_30_dias,0)}}</b>
                        </v-chip>
                    </div>                    
                </template>

                <template slot="promedio" slot-scope="props">
                    <div style="text-align:center !important;" :title="'Cantidad Ventas / Días Ventas = '+props.row.ventas_30_dias+'/'+dias_ventas">
                        {{formatNumberDecCero(props.row.promedio,2)}}
                    </div>                    
                </template>

                <template slot="minimo" slot-scope="props">
                    <div style="text-align:center !important;" :title="'Prom Diario * Días Entrega = '+formatNumberDecCero(props.row.promedio,2)+'*'+dias_entrega">
                        {{formatNumberDecCero(props.row.minimo,2)}}
                    </div>                    
                </template>

                <template slot="reorden" slot-scope="props">
                    <div style="text-align:center !important;" :title="'Prom Diario * Días Entrega + Mínimo = '+formatNumberDecCero(props.row.promedio,2)+'*'+dias_entrega+'+'+formatNumberDecCero(props.row.minimo,2)">                        
                        {{formatNumberDecCero(props.row.reorden,2)}}
                    </div>                    
                </template>

                <template slot="maximo" slot-scope="props">
                    <div style="text-align:center !important;" :title="'Prom Diario * (Días Entrega + Días Surtir) + Mínimo = '+formatNumberDecCero(props.row.promedio,2)+'* ('+dias_entrega+'+'+dias_calculo+')+'+formatNumberDecCero(props.row.minimo,2)">
                        {{formatNumberDecCero(props.row.maximo,2)}}
                    </div>                    
                </template>

                <template slot="por_pedir" slot-scope="props">
                    <div style="text-align:center !important;" :title="'Máximo - Exist. Total = '+formatNumberDecCero(props.row.maximo,2)+'-'+formatNumberDecCero(props.row.actual,2)">                        
                        <b>{{formatNumberDecCero(props.row.por_pedir,2)}}</b>
                    </div>                    
                </template>

                <template slot="en_pedido" slot-scope="props">
                    <div style="text-align:center !important;">                        
                        {{ props.row.en_pedido }}
                    </div>                    
                </template>

                
                <template slot="actions" slot-scope="props">
                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>                    
                  </v-speed-dial>
                </template>
              </v-client-table>
              
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>


  <v-dialog v-model="modal_compras" max-width="85%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Historial Compras (Últimos {{dias_compras}} días) &nbsp;:::&nbsp; {{articulo_editable}}</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_compras = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
            <strong> PRECIO PROMEDIO DE COMPRA: <v-btn small text :loading="loadingPrecioPromedio" title="El Precio Promedio se calcula en base al precio de compra de las existencias. Si muestra 0 es que no hay existencias positivas del producto." v-tippy>${{ parseFloat(precio_promedio_compra).toFixed(2) }}</v-btn> </strong>
            
            <v-client-table ref="vuetable_compras" :data="items_compras" :columns="compras.columns" :options="compras.options">
                <template slot="no_orden" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.no_orden}}</div>
                </template>
                <template slot="fecha" slot-scope="props">
                    <div style="font-size:10px !important;">{{parse_date_time(props.row.fecha)}}</div>
                </template>
                <template slot="estatus" slot-scope="props">
                    <div v-if="props.row.estatus=='Orden Finalizada'" style="font-weight:bold;color:green;font-size:10px !important;">{{props.row.estatus}}</div>
                    <div v-else style="font-weight:bold;color:orange;font-size:10px !important;">{{props.row.estatus}}</div>
                </template>
                <template slot="existencia" slot-scope="props">
                    <div style="text-align:center !important;font-size:10px !important;">{{formatNumberDecCero(props.row.existencia,2)}}</div>
                </template>
                <template slot="cantidad" slot-scope="props">
                    <div style="text-align:center !important;font-size:10px !important;">{{props.row.cantidad}}</div>
                </template>
                <template slot="cantidad_recibida" slot-scope="props">
                    <div v-if="props.row.cantidad > props.row.cantidad_recibida" style="color:red;font-weight:bold;text-align:center !important;font-size:10px !important;">{{props.row.cantidad_recibida}}</div>
                    <div v-else style="color:green;font-weight:bold;text-align:center !important;font-size:10px !important;">{{props.row.cantidad_recibida}}</div>
                </template>
                <template slot="fecha_recibio" slot-scope="props">
                    <div style="font-size:10px !important;">{{parse_date_time(props.row.fecha_recibio)}}</div>
                </template>
                <template slot="proveedor" slot-scope="props">
                    <div style="font-size:10px !important;">{{props.row.proveedor}}</div>
                </template>
                <template slot="observaciones_orden" slot-scope="props">
                    <div class="class_observaciones" style="font-size:8px !important;">{{props.row.observaciones_orden}}</div>
                </template>
                <template slot="observaciones_entrada" slot-scope="props">
                    <div class="class_observaciones" style="font-size:8px !important;">{{props.row.observaciones_entrada}}</div>
                </template>
                
          </v-client-table>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_compras = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modal_ventas" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Historial Ventas (Últimos {{dias_ventas}} días) &nbsp;:::&nbsp; {{articulo_editable}}</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_ventas = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
            <strong> CONSUMO PROMEDIO DIARIO: <v-btn small text :loading="loadingPrecioPromedio" :title="'Cantidad Total Ventas / Dias Ventas = '+ total_venta+'/'+dias_ventas" v-tippy>{{ promedio_venta }}</v-btn> </strong>
            
            <v-client-table ref="vuetable_ventas" :data="items_ventas" :columns="ventas.columns" :options="ventas.options">
                <template slot="fecha" slot-scope="props">
                    <div>{{props.row.fecha}}</div>
                </template>
                <template slot="cantidad" slot-scope="props">
                    <div style="text-align:center !important;">{{props.row.cantidad}}</div>
                </template>                                
                <template slot="precio" slot-scope="props">
                    <div style="text-align:center !important;">{{formatNumberDecCero(props.row.precio,2)}}</div>
                </template>
          </v-client-table>
          <v-row>
                <v-col sm="12" md="4" lg="4">
                <span title="Es la cantidad total vendida en el periodo seleccionado"><strong>Cantidad Vendida del Periodo: </strong>{{total_venta.toFixed(4)}}</span><br>
                <strong>Cantidad Promedio Diaria: </strong>{{promedio_venta}}<br>
                <strong>Precio Promedio: </strong>${{precio_promedio_venta.toFixed(4)}}
                </v-col>
                <v-col sm="12" md="4" lg="4">
                <span :title="'Promedio x Plazo = '+promedio_venta+' x '+dias_entrega"><strong>Mínimo: </strong>{{formatNumberDecCero(minimo,0)}}</span><br>
                <span :title="'Promedio x Plazo + Minimo = '+promedio_venta+' x '+dias_entrega+' + '+minimo"><strong>Reorden: </strong>{{formatNumberDecCero(reorden,0)}}</span><br>
                <span :title="'Promedio x (Plazo + dias ventas) + Minimo = '+promedio_venta+' x ('+(parseInt(dias_entrega)+parseInt(dias_calculo))+') + '+minimo"><strong>Máximo: </strong>{{formatNumberDecCero(maximo,0)}}</span><br>  
                </v-col>
                <v-col sm="12" md="4" lg="4">
                <strong>Existencia: </strong>{{existencia_articulo}}<br>
                <!--strong>Consumo Mínimo: </strong>{{consumo_minimo}}<br>
                <strong>Consumo Medio: </strong>{{consumo_medio}}<br>
                <strong>Consumo Máximo: </strong>{{consumo_maximo}}<br-->
                <u><span :title="'Máximo - Existencia = '+maximo+' - '+existencia_articulo"><strong>Cantidad a Pedir: </strong>{{formatNumberDecCero(cantidad_pedir,0)}}</span></u>
                </v-col>
            </v-row>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_ventas = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modal_existencias" max-width="35%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Existencias Por Sucursal</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_existencias = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>        
            <strong> EXISTENCIA TOTAL: {{ parseFloat(existencia_articulo).toFixed(2) }}</strong>
            <v-client-table ref="vuetableexistencias" :data="articulo_existencias"
                  :columns="columnsExistencias" :options="optionsExistencias">
                  <template slot="sucursal" slot-scope="props">
                      <div style="font-size:10px !important;" >{{props.row.sucursal}}</div>
                  </template>
                  <template slot="existencia" slot-scope="props">
                      <div style="font-size:10px !important;text-align:right;" >{{formatNumberDecCero(props.row.existencia,2)}}</div>
                  </template>
              </v-client-table>
                 

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_existencias = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>        
      </v-card-actions>
    </v-card>
  </v-dialog>


</div>
</template>

<script>
import Vue from "vue";
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent    
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;    
  },
  created: function() {
    this.get_sucursales();
    
    this.getComprasPeriodo();
    this.getArticulos();
    this.getProveedoresCombo();
    this.getCategorias();
  },
  data() {
    return {
        ventas: {
            columns: [
            "fecha",
            "cantidad",
            "precio",
            ],
            options: {
                filterable: false,
                // sortable: ["fecha", "cantidad", "precio", "total"],
                headings: {
                    fecha: "Fecha",
                    cantidad: "Cantidad",
                    precio: "Precio Venta",
                },
            }
        },

        total_venta: 0,
        promedio_venta: 0,
        precio_promedio_venta: 0,
        minimo: 0,
        reorden: 0,
        maximo: 0,
        consumo_minimo: 0,
        consumo_medio: 0,
        consumo_maximo: 0,
        cantidad_pedir: 0,
        items_ventas: [],

        compras_articulos:{},
        articulo_editable:"",
        articulos_existencia_cero:[],
        articulos_por_pedir:[],
        articulos_no_llegaron:[],
        articulos_si_llegaron:[],
        //Configuracion
        dias_ventas: 90,
        dias_calculo: 30,
        dias_compras: 30,
        dias_entrega: 8,
        dias_entrega_original: 8,
        existencia_clave: 20,
        //Indicadores
        total_articulos_cero:0,
        total_articulos_menor:0, 
        total_articulos_no_llegaron:0,
        total_articulos_si_llegaron:0,        

        loading_articulos_cero:false,
        loading_articulos_no_llegaron:false,        

        encabezadoArticulosCero: {
            "Código Barras":"codigo",
            "Artículo":"articulo",
            "Descripción":"descripcion",
            "Categoría":"categoria",
            "Existencia":"existencia"
        }, 
        encabezadoArticulosLlegaron: {
            "Artículo":"articulo",
            "Descripción":"descripcion",
            "No. Pedido/Factura":"no_orden",
            "Fecha":"fecha",
            "Estatus":"estatus",
            "Existencia":"existencia",
            "Cantidad":"cantidad",
            "Cantidad Recibida":"cantidad_recibida",
            "Fecha Recibio/Finalizó":"fecha_recibio",
            "Proveedor":"proveedor",
            "Observaciones Orden":"observaciones_orden",
            "Observaciones Entrada":"observaciones_entrada"                                                
        },     

        id_articulo:"",
        id_proveedor:"",
        nombre_categoria:"",
        proveedores_combo:[],
        categorias:[],
        articulos_encontrados: [],
        loading:false,
        loadingCategorias:false,
        loadingProveedores:false,
        loadingCompras: false,
        loadingArticulos: false,
        loadingVentas: false,

        articulos_en_rojo:0,
        loadingPrecioPromedio:false,
        model_etiqueta: null,
        modal_existencias:false,
        columnsExistencias: ["sucursal","existencia"],
          optionsExistencias: {
              filterable: false,
          },
        sucursales:[],
        articulo_existencias: [],
        existencia_articulo:"",
        precio_promedio_compra:0,
        modal_compras:false,
        modal_ventas:false,
        items_compras: [],
        compras: {
                columns: ["no_orden","fecha","estatus","existencia",
                "cantidad","cantidad_recibida","fecha_recibio",                
                "proveedor","observaciones_orden","observaciones_entrada"
                ],
                options: {
                    filterable: false,
                    // sortable: ["fecha", "cantidad", "precio", "total"],
                    headings: {
                        no_orden:"No. Pedido/Factura",
                        "fecha_recibio":"Fecha Recibio/Finalizó"
                    },
                }
            },
        lakey:"1111",
        modalCantidad:false,
        cantidad_editable:"",
        model_editable: "",
        model_index: 0,
        model_row: "",
        precio_editable: "",
      TASA_IVA: 16, //Por default
      isLoading: false,
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      articulos: [],
      tipos_precios: [],
      fab: [],
      name: "datagrid",
      columns: ["nombre", "descripcion",
                "categoria", "actual", "compras_30_dias",
                "ventas_30_dias", 
                "promedio","minimo","reorden","maximo","por_pedir",
                "en_pedido", 
                //"actions"
                ],
      
      show_loading: true,
      options: {
            perPage: 10,
            pagination: {
                show: true
            },
            filterable: ["nombre", "descripcion","categoria"],
            //filterable: false,
            headings: {
                "nombre": "Artículo",
                "descripcion":"Descripción","categoria":"Categoría","actual":"Exist. Total",
                "compras_30_dias":"Compras N días",
                "ventas_30_dias":"Ventas N días",     
                "promedio":"Cons Promedio Diario",
                "minimo":"Mínimo",
                "reorden":"Reorden",
                "maximo":"Máximo",
                "por_pedir":"Por Pedir",
                "en_pedido":"En Pedido/Factura Pendiente?",
                //"actions": "Opciones",
            },                                    
        },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Compras",
          disabled: true,
          href: ""
        },
        {
          text: "Indicadores Compras",
          href: ""
        }
      ],
        model: {
        _id: "",
        _rev: "",
        type: "articulos",
        codigo_barras: [],
        nombre: "",
        created_at: "",
        estatus: "Activo",
        descripcion: "",
        categoria: "",
        caducidad: "",
        tiene_impuestos: "",
        tiene_grupo: "",
        tiene_informacion: "",
        marca: "",
        precios_venta: [],
        impuestos: [],
        grupo_articulos: [],
        informacion_nutrimental: [],
        producto_servicio: "",
        unidad_medida: "",
        precio_compra: "",
        precio_compra_con_iva: "",
        //nuevos
        equivalencias: [],
        proveedores: []        
      },
      modal: false,
      update: false,      
      rules: {
        required: v => !!v || "Este campo es requerido",
      },            
      registros: {
        selected: [],
        items: [],
        importaciones: []
      }, 
      model_index: 0,
        model_row: "",

    };
  },

  methods: {
    actualizarIndicadores:function(){
        this.loading = true;
        this.getComprasPeriodo();
        this.getArticulos();
        
        setTimeout(() => {
            this.loading = false;
        }, 5000);//damos 10 seg

    },
    soloNumeros: function(value){ 
        // Guarda la posición actual del cursor
        const input = event.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;

        const pattern = /^[\d]*$/;
        if (!pattern.test(value) ){
            value = value.substring(0,value.length-1);
            // Actualiza el valor del input en la vista
            input.value = value;
            this.soloNumeros(value);
        }     
        input.setSelectionRange(start, end);
    },
    parse_date(date) {
        if (!date) return null;
        return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
        if (!date) return null;
        return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    parse_date_time_mx(date) {
        if (!date) return null;
        return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var self = this;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {
        "type": "articulos",
        "estatus":"Activo"
      };

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          if (x == "codigo_barras") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters[x]
              }
            }
          } else if (x == "categoria") {
            where[x] = {
              "$elemMatch": {
                "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
              }
            }
          } else if (x == "precio_compra_con_iva") {
             //where[x] = parseFloat(this.model_filters[x])
            where["$or"] = [
                {"precio_compra_con_iva": parseFloat(this.model_filters[x])}, {"precio_compra_con_iva": {"$regex": "(?i)" + this.model_filters[x]}}
              ]
          } else {
            where[x] = {
              "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
            }
          }
        }
      });


        axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(async response => {
          if (response.data.docs.length) {
            //buscar items nuevos, para que no se dupliquen
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));

            
            await Promise.all(nuevos.map(async (x) => {  
              
                    let datos_consulta = {
                        "id_articulo": x._id,                        
                    };

                    await self.calcula_precio_promedio_row(x._id).then(async precioPromedio => {
                        x["precio_compra_promedio"] = precioPromedio;                        
                    }).catch(err => {
                        console.log(err);
                        console.log("Ocurrió un error al obtener precio promedio de compra de artículo: ", x._id);
                    });

                    await window.funciones_lotes.consultaExistencia(datos_consulta).then(async result => {
                        x["existencia"] = 0;
                        if (result.length > 0) {
                            result.forEach(el => {
                                x["existencia"] += el.existencia;
                            });
                        }                                                                     
                    }).catch(err => {
                        console.log("Ocurrió un error al consultar existencia de artículo: ", x._id);
                    });

                    if (x.precio_proveedor == undefined || x.precio_proveedor == "")
                        x.precio_proveedor = 0.00;

                    x.precio_proveedor = parseFloat(x.precio_compra_con_iva).toFixed(2);
                    
                    if (x.precios_venta != undefined && x.precios_venta.length > 0) {
                        x.publico = "N/A";
                        x.mayoreo = "N/A";
                        x.mayoreo2 = "N/A";
                        x.precios_venta.forEach(function (y) {
                            //console.log("Precios: ", x);
                            if (y.tipo_precio == "Público General") {
                                if(y.precio_con_impuestos != undefined)
                                    x.publico = parseFloat(y.precio_con_impuestos).toFixed(2);
                                else
                                    x.publico = parseFloat(0.00).toFixed(2);
                            }
                            if (y.tipo_precio == "Mayoreo") {
                                if(y.precio_con_impuestos != undefined)
                                    x.mayoreo = parseFloat(y.precio_con_impuestos).toFixed(2);
                                else
                                    x.mayoreo = parseFloat(0.00).toFixed(2);
                            }
                            if (y.tipo_precio == "Mayoreo 2") {
                                if(y.precio_con_impuestos != undefined)
                                    x.mayoreo2 = parseFloat(y.precio_con_impuestos).toFixed(2);
                                else 
                                    x.mayoreo2 = parseFloat(0.00).toFixed(2);
                            }
                        })
                    }                                        
            }));
            this.registros.items = this.registros.items.concat(nuevos);
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    
    getComprasPeriodo: async function() {
        try {
            var self = this;
            var ids_origen = [];
            this.compras_articulos = {};
            
            this.total_articulos_no_llegaron = 0;
            var ids_articulos_no_llegaron = [];
            this.total_articulos_si_llegaron = 0;
            var ids_articulos_si_llegaron = [];

            this.articulos_no_llegaron = [];
            this.articulos_si_llegaron = [];
            this.loading_articulos_no_llegaron = true;

            var fecha = window.moment().subtract(parseInt(this.dias_compras), 'days').format("YYYY-MM-DD");
            var where = {
                "selector": {
                    "type": "pedidos",
                    "estatus":{"$exists": true },
                    "fecha": {"$gte": fecha } 
                },
                "use_index": "_design/060eba6482245aa90249dd488f5ee4f0"
            };

            //PEDIDOS
            await window.axios({
                method: 'POST',
                url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
                withCredentials: true,
                data: where,
            }).then(async response => {
                if (response.data.docs.length) {
                    var docs = response.data.docs;
                    docs.map(function(compra){
                        if(compra["estatus"] != "Orden Cancelada"){
                            ids_origen.push(compra["_id"]);
                            compra["articulos"].map(function(art){
                                if(!self.compras_articulos[art["id_articulo"]]){                                
                                    self.compras_articulos[art["id_articulo"]] = [];                                
                                }
                                var record = {"id":compra["_id"],"type":"pedidos","no_orden":compra["no_orden"], "observaciones_orden":compra["observaciones_orden"],"observaciones_entrada":compra["observaciones_entrada"],
                                    "estatus":compra["estatus"], "fecha":compra["fecha"], "sucursal":compra["nombre_sucursal_destino"], "proveedor":compra["proveedor"]["nombre_proveedor"],
                                    "articulo":art["nombre_articulo"],"descripcion":art["descripcion"], "cantidad":parseFloat(art["cantidad"]), "cantidad_recibida":parseFloat(art["cantidad_recibida"]),
                                    "existencia":art["existencia_articulo"], "fecha_recibio":compra["fecha_finalizada"]
                                }
                                self.compras_articulos[art["id_articulo"]].push(record);
                                self.compras_articulos[art["id_articulo"]].sort(function (a, b) {
                                    //Ordenar por fecha más reciente
                                    return new Date(b.fecha) - new Date(a.fecha);
                                });
                            }); 
                        }
                        
                        
                    });
                    

                    //COMPRAS/FACTURAS 
                    //await Promise.all(ids_origen.map(async (id_origen) => {  
                        /*let data = {
                            selector: {"type":"compras","id_orden_origen":id_origen},
                            use_index:"_design/a382e7c18fba466ff6baeb139f153de0bcf96ece"
                        };*/
                        let data = {
                            selector: {"type":"compras","fecha": {"$gte": fecha }, "estatus":{"$exists":true}},
                            use_index:"_design/fa6a4280324d329a631ce3ae76a2682d6b44d421"
                        };                        
                        await window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_find/", data)
                        .then(responseFacturas => {

                            if (responseFacturas.data.docs.length) {
                                var facturas = responseFacturas.data.docs;
                                facturas.map(function(factura){

                                    if(factura["estatus"] != "Orden Cancelada"){
                                        factura["articulos"].map(function(art){
                                            var encontrado = 0;
                                            if(!self.compras_articulos[art["id_articulo"]]){
                                                self.compras_articulos[art["id_articulo"]] = [];                                
                                            } else {
                                                //Buscar si tiene pedido, si tiene sumar cantidad_recibida y actualizar fecha_recibió solamenbte                                            
                                                self.compras_articulos[art["id_articulo"]].map(function(comp){
                                                    if(comp["type"] == "pedidos" && factura["id_orden_origen"] == comp["id"]){
                                                        encontrado = 1;
                                                        comp["cantidad_recibida"] += parseFloat(art["cantidad_recibida"]);
                                                        comp["fecha_recibio"] = factura["fecha_finalizada"];
                                                    }
                                                });

                                            }
                                            if (encontrado == 0){
                                                var record = {"id":factura["_id"],"type":"compras","no_orden":factura["no_orden"], "observaciones_orden":factura["observaciones_orden"],"observaciones_entrada":factura["observaciones_entrada"],
                                                    "estatus":factura["estatus"]!="Orden Finalizada"?"Entrada Pendiente":factura["estatus"], "fecha":factura["fecha"], "sucursal":factura["nombre_sucursal_destino"], "proveedor":factura["proveedor"]["nombre_proveedor"],
                                                    "articulo":art["nombre_articulo"],"descripcion":art["descripcion"], "cantidad":parseFloat(art["cantidad"]), "cantidad_recibida":parseFloat(art["cantidad_recibida"]),
                                                    "existencia":art["existencia_articulo"], "fecha_recibio":factura["fecha_finalizada"]
                                                }
                                                self.compras_articulos[art["id_articulo"]].push(record);
                                                self.compras_articulos[art["id_articulo"]].sort(function (a, b) {
                                                    //Ordenar por fecha más reciente
                                                    return new Date(b.fecha) - new Date(a.fecha);
                                                });

                                                
                                            }
                                            
                                        });
                                    }
                                    
                                    
                                    
                                });
                            }   
                            
                        })
                        .catch(error => {
                            this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al obtener las facturas. Intente nuevamente.",
                                footer: ""
                            });
                        });
                    //}));
                   
                    
                } 
                Object.keys(self.compras_articulos).forEach(id_articulo => {
                    //console.log(`${id_articulo}: ${self.compras_articulos[id_articulo]}`);
                    
                    var arreglo = self.compras_articulos[id_articulo];
                    arreglo.map(function(record){
                        //Si no se completo la cantidad, se considera que no llego
                        if(record["cantidad_recibida"] < record["cantidad"]){                        
                            if(!ids_articulos_no_llegaron.includes(id_articulo))
                                ids_articulos_no_llegaron.push(id_articulo);
                            
                            self.articulos_no_llegaron.push(record);
                                                                                    
                        } else {
                            if(!ids_articulos_si_llegaron.includes(id_articulo))
                                ids_articulos_si_llegaron.push(id_articulo);
                            
                            self.articulos_si_llegaron.push(record);
                        }
                    });                    
                });

                self.total_articulos_no_llegaron = ids_articulos_no_llegaron.length;
                self.total_articulos_si_llegaron = ids_articulos_si_llegaron.length;
            })
            .catch(error => {
                console.log(error)
            }).then(()=>{
                this.loading_articulos_no_llegaron = false;
            });

        } catch (error) {
            console.log(error);
        }
    },
    showCompras: async function(id_articulo_calculo) {
        try {
            var self = this;
            this.items_compras = [];     

            if (this.compras_articulos[id_articulo_calculo]){
                this.items_compras = this.compras_articulos[id_articulo_calculo];
                this.articulo_editable = this.items_compras[0]["articulo"];
            }

            self.calcula_precio_promedio(id_articulo_calculo);

            this.modal_compras = true;
            
        } catch (error) {
            console.log("Error en showCompras: ", error);
        }
    },
    articuloEnPedidoPendiente:function(id_articulo) {
        try {
            var self = this;
            var result = "";    

            if (this.compras_articulos[id_articulo]){
                var compras = this.compras_articulos[id_articulo];
                for(var k in compras){
                    var compra = compras[k];
                    if(compra["estatus"] != "Orden Finalizada"){
                        result = "SI";
                        break;
                    }
                }                
            }

            return result;
            
        } catch (error) {
            console.log("Error en articuloEnPedidoPendiente: ", error);
        }
    },
    getCompras: async function(id_articulo_calculo) {
        var self = this;
            return new Promise(async function (resolve, reject) {
            try {                
                var cantidad_compras = 0;                      

                var today = window.moment().format("YYYY-MM-DD");
                var fecha_de_venta = window.moment().subtract(parseInt(self.dias_compras), 'days').format("YYYY-MM-DD");
                var fecha_a_venta = today;

                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_grupo';
                const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                let urlConditions = `?startkey=["${id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;

                axios.get(url + urlConditions)
                .then(response => {
                    if (response.data.rows.length > 0) {
                        let respuesta = response.data.rows;
                        
                        respuesta.forEach(x => {
                            let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                            if(x.value.precio_proveedor.toString().trim() != ""){
                                cantidad_compras += parseFloat(x.value.cantidad); 
                            }
                        });                        
                    } 
                    return resolve(cantidad_compras);
                })
                .catch(error => {
                    return resolve(cantidad_compras);
                });
            } catch (error) {
                console.log("Error al consultar compras: ", error);
                return resolve(cantidad_compras);
            }
        });
    },
    calcula_precio_promedio: async function(id_articulo_calculo){
        var self = this;
        this.precio_promedio_compra = 0;
        self.loadingPrecioPromedio = true;
        
        var precio_sumatoria = 0;
        var cantidad_lotes = 0;

        for(var k in this.sucursales){
            var id_sucursal = this.sucursales[k]._id;
            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/lotes_articulo';
              let urlConditions = `?startkey=["${id_sucursal}","${id_articulo_calculo}"]&endkey=["${id_sucursal}","${id_articulo_calculo}"]`;
              await axios.get(url + urlConditions)
              .then(response => {
                  if (response.data.rows.length > 0) {
                    let lotes = response.data.rows;                  
                    //console.log("LOTES: ", lotes);
                    for(var kl in lotes){
                      if( parseFloat(lotes[kl].value.precio_compra) > 0){
                        precio_sumatoria += parseFloat(lotes[kl].value.precio_compra);
                        cantidad_lotes += 1;
                      }
                    }
                   
              } 
            }).catch(error => {
              
            });
        }

        if(cantidad_lotes > 0){
          self.precio_promedio_compra = precio_sumatoria > 0 ? precio_sumatoria / cantidad_lotes : 0;
        }
        self.loadingPrecioPromedio = false;
        
    },
    //Este metodo es con Promise para regresarlo en las filas
    calcula_precio_promedio_row: async function(id_articulo_calculo){
      var self = this;
      var precio_promedio_compra = 0;
      var precio_sumatoria = 0;
      var cantidad_lotes = 0;
      return new Promise(async function(resolve, reject) {
          try{
              if(self.sucursales.length == 0){
                self.registros.items = [];
                self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                return resolve(0);
              }
              for(var k in self.sucursales){
                var id_sucursal = self.sucursales[k]._id;
                let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/lotes_articulo';
                  let urlConditions = `?startkey=["${id_sucursal}","${id_articulo_calculo}"]&endkey=["${id_sucursal}","${id_articulo_calculo}"]`;
                  await axios.get(url + urlConditions)
                  .then(response => {
                      if (response.data.rows.length > 0) {
                        let lotes = response.data.rows;                  
                        //console.log("LOTES: ", lotes);
                        for(var kl in lotes){
                          if( parseFloat(lotes[kl].value.precio_compra) > 0){
                            precio_sumatoria += parseFloat(lotes[kl].value.precio_compra);
                            cantidad_lotes += 1;
                          }
                        }
                      
                  } 
                }).catch(error => {
                  
                });
            }

            if(cantidad_lotes > 0){
              precio_promedio_compra = precio_sumatoria > 0 ? precio_sumatoria / cantidad_lotes : 0;
            }
            return resolve(precio_promedio_compra);

          } catch(e){
              return reject(e);
          }

      });
        
    },

    getVentas: async function(id_articulo, existencia) {

        var self = this;
        window.dialogLoader.show('Espere un momento por favor..');

        this.existencia_articulo = existencia;
        var a = this.getArticulo(id_articulo);
        this.articulo_editable = a !== false? a["nombre"] : "";
        this.total_venta = 0;
        this.promedio_venta = 0;
        this.precio_promedio_venta = 0;
        this.minimo = 0;
        this.reorden = 0;
        this.maximo = 0;
        this.consumo_minimo = 0;
        this.consumo_medio = 0;
        this.consumo_maximo = 0;
        this.cantidad_pedir = 0;
        this.items_ventas = [];

        let plazo = parseFloat(this.dias_entrega);

        let group_level = this.dias_ventas < 31 ? "4" : "3"; //Si los dias son menores a 31 que agrupe por Día, sino por Mes

        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
        var fecha_de = window.moment().subtract(parseInt(self.dias_ventas), 'days').format("YYYY-MM-DD");
        var fecha_a = window.moment().format("YYYY-MM-DD");
        var [year_de, month_de, day_de] = fecha_de.split("-");
        var [year_a, month_a, day_a] = fecha_a.split("-");
        
        let urlConditions = `&startkey=["${id_articulo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${id_articulo}", "${year_a}", "${month_a}", "${day_a}"]`;
        let procesado = [];
        axios.get(url + urlConditions)
        .then(async response => {
            if (response.data.rows.length > 0) {
                let respuesta = response.data.rows;                    

                //Aqui ya seria agrupar por dia, semana o mes
                if (group_level == "4") {
                    //console.log("busqueda por dias")
                    respuesta.forEach(x => {
                        let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                    });
                } else if (group_level == "3") { //if si es por mes

                    //console.log("busqueda por mes")
                    respuesta.forEach(x => {
                        let key = `${x.key[1]}-${x.key[2]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                    });

                } else { // if si es por año
                    //console.log("busqueda por año");
                    respuesta.forEach(x => {
                        let key = `${x.key[1]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                    });
                }
            }    
            
            //Obtener articulos equivalentes y obtener sus registros
            await self.get_ventas_articulo(id_articulo).then(function(equivalencias_procesado){
                procesado = procesado.concat(equivalencias_procesado); 

                var new_procesado = [];
                //juntarlo por key
                for(var k in procesado){
                    var fecha_key = new_procesado.find(e => e.fecha == procesado[k].fecha);
                    if(fecha_key){
                        fecha_key.cantidad += procesado[k].cantidad;
                        fecha_key.precio = (fecha_key.precio + procesado[k].precio)/ 2; //El que ya tenía, mas el de la misma fecha, entre 2, ya no entre cantidad
                    } else {
                        new_procesado.push(procesado[k]);
                    }
                }

                new_procesado.sort((a,b) => {
                    if (a.fecha < b.fecha) {
                        return -1;
                    }
                    if (a.fecha > b.fecha) {
                        return 1;
                    }
                    // si son iguales
                    return 0;
                });


                //llenar los datos de ventas
                self.total_venta = 0;
                self.promedio_venta = 0;
                self.precio_promedio_venta = 0;

                let sorted = []
                new_procesado.forEach(x => {
                    sorted.push(x);
                    self.total_venta += x.cantidad;
                    self.promedio_venta += x.cantidad;
                    self.precio_promedio_venta += x.precio;
                });

                let numero_dias_tiempo = new Date(fecha_a) - new Date(fecha_de);
                let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);
                //console.log("DIAS: ", numero_dias);
                if(numero_dias <= 0)
                    numero_dias = 1;
                

                self.promedio_venta = self.promedio_venta > 0 ? parseFloat((self.promedio_venta / numero_dias).toFixed(2)) : 0;
                self.precio_promedio_venta = self.precio_promedio_venta > 0 ? self.precio_promedio_venta / new_procesado.length : 0;

                //Falta sacar los datos del articulo maximo, minimo, etc
                if (sorted.length > 0) {
                    sorted.sort((a, b) => {
                        return a.cantidad - b.cantidad;
                    });

                    //CALCULO PILOS
                    /*self.consumo_minimo = self.periodo == "1" ? Math.ceil(sorted[0].cantidad) : (self.periodo == "2" ? Math.ceil(sorted[0].cantidad / 30) : Math.ceil(sorted[0].cantidad / 365));
                    self.consumo_maximo = self.periodo == "1" ? Math.ceil(sorted[sorted.length - 1].cantidad) : (self.periodo == "2" ? Math.ceil(sorted[sorted.length - 1].cantidad / 30) : Math.ceil(sorted[sorted.length - 1].cantidad / 365));
                    self.consumo_medio = self.promedio_venta;

                    self.minimo = self.consumo_minimo * plazo;
                    self.reorden = (self.consumo_medio * plazo) + self.minimo;
                    self.maximo = (self.consumo_maximo * plazo) + self.minimo;

                    self.cantidad_pedir = parseFloat((self.maximo - self.existencia_articulo).toFixed(2));*/

                    //CALCULO ENRIQUE
                    self.minimo = Math.ceil(self.promedio_venta * plazo);
                    self.reorden = Math.ceil((self.promedio_venta * plazo) + self.minimo);
                    self.maximo = Math.ceil((self.promedio_venta * (plazo+30)) + self.minimo); //Suponiendo que quieren surtir para 30 días 

                    if(parseFloat(self.existencia_articulo) >= self.maximo){
                        self.cantidad_pedir = 0;
                    } else {
                        self.cantidad_pedir = parseFloat((self.maximo - self.existencia_articulo).toFixed(2));
                    }
                    

                }                

                //Poner arreglo en reversa para mostrar la venta mas reciente al inicio
                self.items_ventas = new_procesado.reverse();
                self.modal_ventas = true;

            });

        })
        .catch(error => {
            console.log(error)
        }).then(()=>{               
            window.dialogLoader.hide();
        });
    },

    get_ventas_articulo: function(id_articulo_padre, cantidad_padre = 1){
        var self = this;
        return new Promise(async function (resolve, reject) {
            let procesado = [];
            let group_level = self.dias_ventas < 31 ? "4" : "3"; //Si los dias son menores a 31 que agrupe por Día, sino por Mes

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
            var fecha_de = window.moment().subtract(parseInt(self.dias_ventas), 'days').format("YYYY-MM-DD");
        var fecha_a = window.moment().format("YYYY-MM-DD");
            const [year_de, month_de, day_de] = fecha_de.split("-");
            const [year_a, month_a, day_a] = fecha_a.split("-");

            var busca = encodeURI(escape(id_articulo_padre));
            
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
            .then(async responseEquivalencias => {
                if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                    //console.log("Buscando equivalencias artículo: ", id_articulo_padre);
                    for(var ke in responseEquivalencias.data.rows){
                        var equivalente = responseEquivalencias.data.rows[ke].value;
                        
                        var urlConditions = `&startkey=["${equivalente['id_articulo_equivalente']}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${equivalente['id_articulo_equivalente']}", "${year_a}", "${month_a}", "${day_a}"]`;

                        await axios.get(url + urlConditions)
                        .then(async response => {
                            if (response.data.rows.length > 0) {
                                //console.log("Buscando ventas artículo: ", equivalente['id_articulo_equivalente']);
                                let respuesta = response.data.rows;                    

                                //Aqui ya seria agrupar por dia, semana o mes
                                if (group_level == "4") {
                                    //console.log("busqueda por dias")
                                    respuesta.forEach(x => {
                                        let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;
                                        var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                        procesado.push({
                                            "fecha": key,
                                            "cantidad": cantidad_calculada,
                                            "precio": x.value.precio_venta / cantidad_calculada, //Aqui no se divide pues el precio del equivalente es diferente al de la caja, se deja lo que se vendió como su precio
                                            "total": 0
                                        });
                                    });
                                } else if (group_level == "3") { //if si es por mes

                                    //console.log("busqueda por mes")
                                    respuesta.forEach(x => {
                                        let key = `${x.key[1]}-${x.key[2]}`;
                                        var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                        procesado.push({
                                            "fecha": key,
                                            "cantidad": cantidad_calculada,
                                            "precio": x.value.precio_venta / cantidad_calculada,
                                            "total": 0
                                        });
                                    });

                                } else { // if si es por año
                                    //console.log("busqueda por año");
                                    respuesta.forEach(x => {
                                        let key = `${x.key[1]}`;
                                        var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                        procesado.push({
                                            "fecha": key,
                                            "cantidad": cantidad_calculada,
                                            "precio": x.value.precio_venta / cantidad_calculada,
                                            "total": 0
                                        });
                                    });
                                }

                                //Buscamos si el equivalente tiene un subequivalente
                                await self.get_ventas_articulo(equivalente["id_articulo_equivalente"], equivalente["cantidad"]).then(function(equivalencias_procesado){
                                    procesado = procesado.concat(equivalencias_procesado); 
                                });                                    
                                
                            }
                        })
                        .catch(error => {
                            console.log(error);                            
                        });                        

                    }
                    return resolve(procesado);
                } else {
                    return resolve(procesado);
                }
            })
            .catch(error => {
                console.log("ERROR al buscar equivalencia2: ", error);   
                return resolve(procesado);  
            });
        });
        
    },
   
    validaCantidadFloatRequerido: function (value) {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        if (value != null && value != "" && !pattern.test(value)) {
            return "Cantidad no válida"
        } else if (value != null && value != "") {
            if (parseFloat(value) <= 0)
                return "Debe ser mayor que cero";
        } else if (value != null && value == "")
            return "Campo requerido";
        return true;
    },
    validaMaximo: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && pattern.test(value)) {
        if(parseFloat(value) > 100000)
          return "Cantidad demasiado grande";
      } 
      return true;

    },
    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    validaCantidadFloatNegativo: function(value) {
      const pattern = /^[\-]{0,1}[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },
    
        get_sucursales: function () {            
            let data = {
                "selector": {
                    "nombre":{"$exists":true}
                },
                "fields": [
                    "nombre", "_id","abastos","matriz"
                ],
                "use_index": "_design/84f098ad708a26e38d78e9daf0da60ca49fd02ea"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    this.sucursales = [];
                    if (response.data.docs.length > 0){
                        this.sucursales = response.data.docs;                        
                    }                                      
                }).catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });
                });
        },
        getNombreSucursal: function(id_sucursal) {
            var filter = this.sucursales.find(e => e._id == id_sucursal);
            if (filter)
                return filter.nombre;
            else
                return "";
        },
        formatNumberDec: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                numero = 0;
            numero = Number(numero);
            if (isNaN(numero)){
                return "";
            } else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        formatNumberDecCero: function(numero, decimales){
            if (numero == undefined || numero == null || numero == "")
                numero = 0;
            numero = Number(numero);
            if (isNaN(numero)){
                return "";
            } else{
              if(parseFloat(numero) == parseInt(numero))
                return numero.toString().replace(/\d(?=(\d{3}))/g, '$&,');
              else
                return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
                
        },
        ver_existencias: async function(row) {
            let n = 0;
            var self = this;
            await window.funciones_lotes.consultaExistencia({
                "id_articulo": row._id
            }).then(result => {
                if (result.length > 0) {

                    result.forEach(el => {
                        n += el.existencia
                        el["sucursal"] = self.getNombreSucursal(el.id_sucursal);
                    });
                    this.articulo_existencias = result;
                    this.existencia_articulo = n;
                } else {
                    this.existencia_articulo = 0;
                    this.articulo_existencias = [];
                }
                this.modal_existencias = true;
                //console.log("se asigno la existencia");

            }).catch(err => {
                console.log("error", err);
                this.existencia_articulo = 0;
                this.articulo_existencias = [];
            });
        },
       
        get_conteo_ventas_articulo: function(id_articulo_padre, cantidad_padre = 1){
            var self = this;
            var conteo = {"v30":0, "v60":0};
            let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
            //Ultimos N días, configurados en la vista
            var fecha_de_venta = window.moment().subtract(this.dias_ventas, 'days').format("YYYY-MM-DD");
            var fecha_a_venta = window.moment().format("YYYY-MM-DD");
            const [year_de, month_de, day_de] = fecha_de_venta.split("-");
            const [year_a, month_a, day_a] = fecha_a_venta.split("-");
            
            return new Promise(async function (resolve, reject) {                
               
                var busca = encodeURI(escape(id_articulo_padre));                
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
                .then(async responseEquivalencias => {
                    if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                        //console.log("Buscando equivalencias artículo: ", id_articulo_padre);
                        for(var ke in responseEquivalencias.data.rows){
                            var equivalente = responseEquivalencias.data.rows[ke].value;
                            
                            var urlConditions = `&startkey=["${equivalente['id_articulo_equivalente']}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${equivalente['id_articulo_equivalente']}", "${year_a}", "${month_a}", "${day_a}"]`;
                            
                            await axios.get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                        conteo["v30"] += cantidad_calculada;                                        
                                    });
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            });                                                    

                            await self.get_conteo_ventas_articulo(equivalente['id_articulo_equivalente'], equivalente["cantidad"]).then(function(conteo2){
                                conteo["v30"] += conteo2["v30"];                                
                            });

                        }
                        return resolve(conteo);
                    } else {
                        return resolve(conteo);
                    }
                })
                .catch(error => {
                    console.log("ERROR al buscar ventas de quivalencia: ", error);   
                    return resolve(conteo);  
                });
            });
            
        },
        consultarArticulo: function () {
            if (this.id_articulo != null && this.id_articulo != "") {
                this.id_proveedor = null;                
                this.nombre_categoria = null;
                this.$refs.vuetable.resetQuery();
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;
                self.dias_entrega = self.dias_entrega_original;
                
                var busca = encodeURI(escape(self.id_articulo));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_articulo?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        //Solo los articulos que tengan proveedor
                        var filtro = response.data.rows.filter(function(item){
                            return item.value.proveedor.id_proveedor != undefined;
                        });

                        var rows = [];
                        var rows_ids_articulo = [];
                        //Para que no se repita el articulo, ya que la vista los arroja por proveedor
                        filtro.forEach(function(item){
                            if(!rows_ids_articulo.includes(item.value.articulo._id)){
                                rows.push(item.value.articulo);
                                rows_ids_articulo.push(item.value.articulo._id)
                            }                            
                        });  

                        var articulos = [];
                        var proveedores = {};
                        //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                        let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                        
                        //Ultimos N días, configurados en la vista
                        var fecha_de_venta = window.moment().subtract(parseInt(this.dias_ventas), 'days').format("YYYY-MM-DD");
                        var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                        const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                        const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                        
                        //for (var k in rows) {
                        await Promise.all(rows.map(async (item) => {

                            var articulo = item;
                            articulo["ventas_30_dias"] = 0;
                            articulo["promedio"] = 0;
                            articulo["minimo"] = 0;
                            articulo["reorden"] = 0;
                            articulo["maximo"] = 0;
                            articulo["por_pedir"] = 0;
                            articulo["en_pedido"] = self.articuloEnPedidoPendiente(articulo._id);
                            let urlConditions = `&startkey=["${articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;

                            await axios
                            .get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        articulo["ventas_30_dias"] += x.value.cantidad;
                                    });
                                }

                                //Contamos ventas de equivalentes
                                await this.get_conteo_ventas_articulo(articulo._id).then(function(conteo){
                                    articulo["ventas_30_dias"] += conteo["v30"];                                                                      
                                });                            

                            })
                            .catch(error => {
                                console.log(error);
                            }).then(async ()=>{
                                let _actual = 0;
                                
                                let datos_consulta = {
                                    "id_articulo": articulo._id,
                                    //Martin solicitó que se muestre la existencia de matriz en la tabla
                                    //"id_sucursal": self.suc_matriz._id
                                };
                                
                                await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                    //console.log("Existencias: ", result);
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            _actual += el.existencia;
                                        });
                                    }
                                    //_actual += result;
                                    articulo['actual'] = _actual  
                                    
                                    if(articulo["ventas_30_dias"] > 0){
                                        articulo["promedio"] = parseFloat(articulo["ventas_30_dias"] / parseFloat(self.dias_ventas)).toFixed(2);
                                        articulo["minimo"] = Math.ceil(articulo["promedio"] * parseFloat(self.dias_entrega));
                                        articulo["reorden"] = Math.ceil((articulo["promedio"] * parseFloat(self.dias_entrega)) + articulo["minimo"]);
                                        articulo["maximo"] = Math.ceil((articulo["promedio"] * (parseFloat(self.dias_entrega)+parseFloat(self.dias_calculo))) + articulo["minimo"]);
                                        if(parseFloat(articulo['actual']) >= articulo["maximo"]){
                                            articulo["por_pedir"] = 0;
                                        } else {
                                            articulo["por_pedir"] = parseFloat((articulo["maximo"] - parseFloat(articulo['actual'])).toFixed(2));
                                        }
                                        
                                    }
                                    
                                }).catch(err => {
                                    console.log("Ocurrió un error al consultar existencia de artículo.");

                                });
                            });

                            //Compras
                            await self.getCompras(articulo._id).then(function(cantidad_comprada){
                                articulo["compras_30_dias"] = cantidad_comprada; 
                            });

                            articulos.push(articulo);
                        }));
                        if( articulos.length>0)
                            self.articulos_encontrados = articulos;
                        else{
                            self.articulos_encontrados = [];
                            self.$swal({
                                type: "warning",
                                title: "¡Artículo no relacionado!",
                                text: "El artículo, no está relacionado a ningún proveedor. Favor de verificar.",
                                footer: ""
                            });

                        }
                    } else {
                        self.articulos_encontrados = [];
                        self.$swal({
                            type: "warning",
                            title: "¡Artículo no relacionado!",
                            text: "El artículo, no está relacionado a ningún proveedor. Favor de verificar.",
                            footer: ""
                        });
                    }
                })
                .catch(err => {
                    console.log("Error en consultarArticulo: ", err);
                }).then(()=>{
                    window.dialogLoader.hide();
                });

            }
        },
        ordenar_nombre: function( a, b ){
            if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
                return -1;
            }
            if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
                return 1;
            }
            return 0;
        },
        getArticulos:function(){
            var self = this;
            self.loading_articulos_cero = true; 
            self.articulos_existencia_cero = [];
            self.articulos_por_pedir = [];
            self.total_articulos_cero = 0;
            self.total_articulos_menor = 0; 
            this.loadingArticulos = true;
            let data = {
                "selector": {
                    "type": "articulos",
                    "estatus":"Activo",
                    //"proveedores.0": { "$exists": true }
                },
                "fields":["_id","nombre","descripcion","categoria","codigo_barras","caducidad","impuestos",
                    "precio_compra","precio_compra_con_iva","proveedores"],
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };
            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(async response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.articulos = response.data.docs;

                    var solo_con_proveedor = self.articulos.filter(function(e){
                        return e.proveedores && e.proveedores.length>0});
                    

                    await window.axios
                        //.get(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_total?group=true&key="'+data.id_articulo+'"')
                        .get(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_total?group=true')
                        .then(async response => {
                            if(response.data!=null && response.data.rows!=null && response.data.rows.length>0){
                                var art_existencia = {};
                                
                                response.data.rows.map((e) => {
                                    if(art_existencia[e.key]==undefined)
                                        art_existencia[e.key] = e.value;
                                });
                                

                                self.loading_articulos_cero = false;
                                solo_con_proveedor.map((item) => {
                                    var record = {
                                        "codigo":item.codigo_barras[0],
                                        "articulo":item.nombre,
                                        "descripcion":item.descripcion,
                                        "categoria":item.categoria.join(", "),
                                        "existencia":art_existencia[item._id]?parseFloat(art_existencia[item._id].toFixed(2)):0,
                                    };
                                    if(art_existencia[item._id]!=null && art_existencia[item._id] <= 0){
                                        self.articulos_existencia_cero.push(record);
                                        self.total_articulos_cero += 1;            
                                    } else if (art_existencia[item._id]!=null && art_existencia[item._id] > 0 && art_existencia[item._id] < self.existencia_clave){
                                        self.articulos_por_pedir.push(record);
                                        self.total_articulos_menor += 1;
                                    }
                                });

                                
                                                                
                            } 
                        })
                        .catch(err => {
                            console.log("err en existencia_total: ", err);
                        });                 
                })
                .catch(error => {
                    
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(() => {
                    this.loadingArticulos = false;
                    self.loading_articulos_cero = false; 
                });
        },
        getArticulo: function(id_articulo){
            var filtro = this.articulos.find(function(e){
                return e._id == id_articulo;
            });
            if(filtro){
                return filtro;
            } else {
                return false;
            }
        },
        consultarCategoria: function () {
            if (this.nombre_categoria != null && this.nombre_categoria != "") {
                this.id_articulo = null;
                this.id_proveedor = null;
                this.$refs.vuetable.resetQuery();
                window.dialogLoader.show('Espere un momento por favor..');

                var self = this;
                self.dias_entrega = self.dias_entrega_original;

                var busca = encodeURI(escape(this.nombre_categoria.toString().trim().toLowerCase()));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_categoria?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        //Solo los articulos que tengan proveedor
                        var filtro = response.data.rows.filter(function(item){
                            return item.value.proveedor.id_proveedor != undefined;
                        });

                        var rows = [];
                        var rows_ids_articulo = [];
                        //Para que no se repita el articulo, ya que la vista los arroja por proveedor
                        filtro.forEach(function(item){                            
                            if(!rows_ids_articulo.includes(item.value.articulo._id)){
                                rows.push(item.value.articulo);
                                rows_ids_articulo.push(item.value.articulo._id)
                            }                            
                        });  

                        var articulos = [];
                        var proveedores = {};
                        //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                        let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                        //Ultimos N días, configurados en la vista
                        var fecha_de_venta = window.moment().subtract(this.dias_ventas, 'days').format("YYYY-MM-DD");
                        var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                        const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                        const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                        

                        //for (var k in rows) {
                        await Promise.all(rows.map(async (item) => {
                            var articulo = item;

                            articulo["ventas_30_dias"] = 0;
                            articulo["promedio"] = 0;
                            articulo["minimo"] = 0;
                            articulo["reorden"] = 0;
                            articulo["maximo"] = 0;
                            articulo["por_pedir"] = 0;
                            articulo["en_pedido"] = self.articuloEnPedidoPendiente(articulo._id);
                            let urlConditions = `&startkey=["${articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                            
                            await axios
                            .get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        articulo["ventas_30_dias"] += x.value.cantidad;
                                    });
                                }

                                //Contamos ventas de equivalentes
                                await this.get_conteo_ventas_articulo(articulo._id).then(function(conteo){
                                    articulo["ventas_30_dias"] += conteo["v30"];                                                                       
                                });

                            })
                            .catch(error => {
                                console.log(error);
                            }).then(async ()=>{
                                let _actual = 0;
                                
                                let datos_consulta = {
                                    "id_articulo": articulo._id,
                                    //Martin solicitó que se muestre la existencia de matriz en la tabla
                                    //"id_sucursal": self.suc_matriz._id
                                };
                                
                                await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                    //console.log("Existencias: ", result);
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            _actual += el.existencia;
                                        });
                                    }
                                    articulo['actual'] = _actual

                                    if(articulo["ventas_30_dias"] > 0){
                                        articulo["promedio"] = parseFloat((articulo["ventas_30_dias"] / parseFloat(self.dias_ventas)).toFixed(2));
                                        articulo["minimo"] =  Math.ceil(articulo["promedio"] * parseFloat(self.dias_entrega));
                                        articulo["reorden"] =  Math.ceil((articulo["promedio"] * parseFloat(self.dias_entrega)) + articulo["minimo"]);
                                        articulo["maximo"] =  Math.ceil((articulo["promedio"] * (parseFloat(self.dias_entrega)+parseFloat(self.dias_calculo))) + articulo["minimo"]);
                                        if(parseFloat(articulo['actual']) >= articulo["maximo"]){
                                            articulo["por_pedir"] = 0;
                                        } else {
                                            articulo["por_pedir"] = parseFloat((articulo["maximo"] - parseFloat(articulo['actual'])).toFixed(2));
                                        }
                                    }
                                    
                                }).catch(err => {
                                    console.log("Ocurrió un error al consultar existencia de artículo.");

                                });
                            });

                            //Compras
                            await self.getCompras(articulo._id).then(function(cantidad_comprada){
                                articulo["compras_30_dias"] = cantidad_comprada; 
                            });

                            articulos.push(articulo);
                        }));
                        if( articulos.length>0){
                            self.articulos_encontrados = articulos;                            
                        } else{
                            self.articulos_encontrados = [];
                            self.$swal({
                                type: "warning",
                                title: "¡Artículo no relacionado!",
                                text: "Los artículos de la categoría, no está relacionados a ningún proveedor. Favor de verificar.",
                                footer: ""
                            });

                        }
                    } else {
                        self.articulos_encontrados = [];
                        window.dialogLoader.hide();
                        self.$swal({
                            type: "warning",
                            title: "¡Artículos no encontrados!",
                            text: "No hay artículos en esta categoría. Favor de verificar.",
                            footer: ""
                        });
                    }

                }).catch(error => {
                    window.dialogLoader.hide();
                    console.log(error);
                    self.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos filtrados.",
                        footer: ""
                    });
                }).then(()=>{
                    window.dialogLoader.hide();
                });
            }
        },
        getProveedoresCombo() {
            this.loadingProveedores = true;
            let data = {
                "selector": {
                    "type": "proveedores",
                    "estatus":"Activo"
                },
                //"fields":["_id", "nombre"],
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.proveedores_combo = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los proveedores.",
                        footer: ""
                    });
                }).then(()=>{
                    this.loadingProveedores=false;
                });
        },

        getProveedor: function(id_proveedor){
            var filtro = this.proveedores_combo.find(function(e){
                return e._id == id_proveedor;
            });
            if(filtro){
                return filtro;
            } else {
                return false;
            }
        },

        consultarProveedor_combo: function () {
            if(this.id_proveedor != null && this.id_proveedor!=""){
                this.id_articulo = null;
                this.nombre_categoria = null;
                this.$refs.vuetable.resetQuery();
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;
                var proveedor = self.getProveedor(self.id_proveedor);

                if(proveedor !== false){
                    if (proveedor["plazo"]!=null){
                        try {
                            self.dias_entrega = parseInt(proveedor["plazo"]);    
                        } catch (error) {
                            self.dias_entrega = self.dias_entrega_original;
                        }
                        
                    }
                }

                var busca = encodeURI(escape(self.id_proveedor));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        var rows = response.data.rows;

                        var articulos = [];
                        //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                        let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                        //Ultimos N días, configurados en la vista
                        var fecha_de_venta = window.moment().subtract(this.dias_ventas, 'days').format("YYYY-MM-DD");
                        var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                        const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                        const [year_a, month_a, day_a] = fecha_a_venta.split("-");                        

                        //for (var k in rows) {
                        await Promise.all(rows.map(async (item) => {
                            var articulo = item.value;

                            articulo["ventas_30_dias"] = 0;
                            articulo["promedio"] = 0;
                            articulo["minimo"] = 0;
                            articulo["reorden"] = 0;
                            articulo["maximo"] = 0;
                            articulo["por_pedir"] = 0;
                            articulo["en_pedido"] = self.articuloEnPedidoPendiente(articulo._id);
                            let urlConditions = `&startkey=["${articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                            
                            await axios
                            .get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        articulo["ventas_30_dias"] += x.value.cantidad;
                                    });
                                }

                                //Contamos ventas de equivalentes
                                await this.get_conteo_ventas_articulo(articulo._id).then(function(conteo){
                                    articulo["ventas_30_dias"] += conteo["v30"];                                                                      
                                });

                            })
                            .catch(error => {
                                console.log(error);
                            }).then(async ()=>{
                                let _actual = 0;
                                
                                let datos_consulta = {
                                    "id_articulo": articulo._id,
                                    //Martin solicitó que se muestre la existencia de matriz en la tabla
                                    //"id_sucursal": self.suc_matriz._id
                                };
                                
                                await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                    //console.log("Existencias: ", result);
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            _actual += el.existencia;
                                        });
                                    }
                                    //_actual += result;
                                    articulo['actual'] = _actual  
                                    
                                    if(articulo["ventas_30_dias"] > 0){
                                        articulo["promedio"] = parseFloat(articulo["ventas_30_dias"] / parseFloat(self.dias_ventas)).toFixed(2);
                                        articulo["minimo"] = Math.ceil(articulo["promedio"] * parseFloat(self.dias_entrega));
                                        articulo["reorden"] = Math.ceil((articulo["promedio"] * parseFloat(self.dias_entrega)) + articulo["minimo"]);
                                        articulo["maximo"] = Math.ceil((articulo["promedio"] * (parseFloat(self.dias_entrega)+parseFloat(self.dias_calculo))) + articulo["minimo"]);
                                        if(parseFloat(articulo['actual']) >= articulo["maximo"]){
                                            articulo["por_pedir"] = 0;
                                        } else {
                                            articulo["por_pedir"] = parseFloat((articulo["maximo"] - parseFloat(articulo['actual'])).toFixed(2));
                                        }
                                        
                                    }                                    
                                    
                                }).catch(err => {
                                    console.log("Ocurrió un error al consultar existencia de artículo.");

                                });
                            });

                            //Compras
                            await self.getCompras(articulo._id).then(function(cantidad_comprada){
                                articulo["compras_30_dias"] = cantidad_comprada; 
                            });

                            articulos.push(articulo);
                        }));

                        self.articulos_encontrados = articulos;

                    } else {
                        self.articulos_encontrados = [];
                        self.$swal({
                            type: "warning",
                            title: "Proveedor sin artículos!",
                            text: "El proveedor, no tiene artículos relacionados. Favor de verificar.",
                            footer: ""
                        });
                    }
                })
                .catch(err => {
                    console.log("Error en consultarProveedor: ", err);
                }).then(()=>{
                    window.dialogLoader.hide();
                });
            }

        },

        getCategorias() {

            this.loadingCategorias = true;
            let data = {
                "selector": {
                    "type": "categorias",
                    "estatus": "Activo"
                },
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.categorias = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las categorias.",
                        footer: ""
                    });
                }).then(() => {
                    this.loadingCategorias = false;
                });
        },
        excel_articulos_cero: function(){            
            var el = document.getElementById("btn_existencia_cero");
            el.click();
        },
        excel_articulos_pedir: function(){            
            var el = document.getElementById("btn_articulos_pedir");
            el.click();
        },
        excel_articulos_no_llegaron: function(){            
            var el = document.getElementById("btn_articulos_no_llegaron");
            el.click();
        },
        excel_articulos_si_llegaron: function(){            
            var el = document.getElementById("btn_articulos_si_llegaron");
            el.click();
        },

  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}

.table-bordered thead th,
.table-bordered thead td {
  font-size: x-small !important;
}
.bg-amarillo {
  background-color: #dae115 !important;
}
.v-dialog .class_observaciones  {
  white-space: normal !important;
  min-width: 200px !important;
  max-width: 200px !important;  
}
</style>
